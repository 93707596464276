@import '../../styles/variables.scss';

.container {
  position: relative;
  overflow-x: auto;
  border: none;
  height: 100%;
}

.tableContainer {
  overflow-x: auto;
  height: 100%;
}

.loading {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.table {
  position: relative;
  vertical-align: initial;
  border: none;

  .header {
    position: sticky;
    top: 0;
    z-index: map-get($layers, above_table_hovered_row);
    overflow: hidden !important;
    border: none;
    padding: 0.5rem;
    height: 1%;
    white-space: nowrap;
    background-color: white;

    .headerContent {
      display: flex;
      justify-content: space-between;
    }
  }

  .row {
    height: 35px;

    .cell {
      vertical-align: middle;
      border: 0.5px solid white;
      padding: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      background-color: #f5f6f8;

      &--selectedRow {
        background: rgba(black, 0.1);
      }
    }

    &:hover {
      .cell {
        background-color: #f1f1f1;
      }
    }
  }

  .filterCell {
    position: sticky;
    top: 36px;
    overflow: visible !important;
    border: none;
    background-color: white;
  }

  .noResults {
    vertical-align: middle;
    font-weight: 300;
    font-size: 30px;
    text-align: center;
    color: #5f5f5f;
  }

  &--noResults {
    height: 100%;
  }
}

.hide {
  display: none;
}

:global(.c-dark-theme) {
  .table {
    .header {
      border-bottom: none;
      background-color: #23242d;
    }

    .expandedRow {
      &:hover {
        background-color: transparent;
      }
    }

    .row {
      .cell {
        border: none;
        background-color: #23242d;

        &--selectedRow {
          background: #3e3e3e !important;
        }
      }

      &:hover {
        .cell {
          background-color: #282931;
        }
      }

      &:nth-child(odd) {
        td {
          border: none;
          background-color: #21222c;
        }
      }
    }

    .filterCell {
      border-bottom: none;
      background-color: #23242d;
    }
  }
}
