.container {
  display: flex;
  overflow: hidden;
  padding: 15px;
  height: 100%;
  gap: 15px;
}

.content {
  position: relative;
  display: inline-flex;
  flex: 7;
  overflow: hidden;
  height: 100%;
  transition: width 0.4s;
  transition-delay: 0.2s;
}

.form {
  position: relative;
  display: inline-block;
  width: 0;
  height: 100%;
  opacity: 0;
  transition: width 0.4s, opacity 0.8s;
  transition-delay: 0.2s;

  &--formOpen {
    width: 30%;
    opacity: 1;
  }
}