@import '../../../styles/variables.scss';

.btn {
  outline: 0 !important;
  border-width: 0;
  border-radius: 5px;
  padding: 7px 16px;
  font: inherit;
  font-size: 14px;
  color: #3c4b64;

  &:not(:disabled):hover {
    filter: brightness(90%);
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--transparent {
    border-color: transparent;
    color: #3c4b64;
    background: transparent;

    &:focus {
      outline: none;
    }

    &:hover {
      background: #f5f6f8;
    }
  }

  &--primary {
    border-color: rgba(map-get($colors, primary), 0.5);
    color: white;
    background-color: map-get($colors, primary);
  }

  &--secondary {
    border-color: rgba(map-get($colors, secondary), 0.5);
    color: #4d4d4d;
    background-color: map-get($colors, secondary);
  }

  &--tertiary {
    border-color: rgba(map-get($colors, tertiary), 0.5);
    color: white;
    background-color: map-get($colors, tertiary);
  }

  &--success {
    border-color: rgba(map-get($colors, success), 0.5);
    color: white;
    background-color: map-get($colors, success);
  }

  &--info {
    border-color: rgba(map-get($colors, info), 0.5);
    color: white;
    background-color: map-get($colors, info);
  }

  &--danger {
    border-color: rgba(map-get($colors, danger), 0.5);
    color: white;
    background-color: map-get($colors, danger);
  }

  &--warning {
    border-color: rgba(map-get($colors, warning), 0.5);
    color: white;
    background-color: map-get($colors, warning);
  }

  &--dark {
    color: white;
    background-color: map-get($colors, dark);
  }
}

:global(.c-dark-theme) {
  .btn {
    &--transparent {
      border-color: transparent;
      color: white;
      background-color: transparent;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    &--primary {
      border-color: rgba(map-get($colors-dark, primary), 0.5);
      background-color: map-get($colors-dark, primary);
    }

    &--secondary {
      border-color: rgba(map-get($colors-dark, secondary), 0.5);
      color: #ebedef;
      background-color: map-get($colors-dark, secondary);
    }

    &--tertiary {
      border-color: rgba(map-get($colors-dark, tertiary), 0.5);
      color: white;
      background-color: map-get($colors-dark, tertiary);
    }

    &--success {
      border-color: rgba(map-get($colors-dark, success), 0.5);
      background-color: map-get($colors-dark, success);
    }

    &--info {
      border-color: rgba(map-get($colors-dark, info), 0.5);
      color: white;
      background-color: map-get($colors-dark, info);
    }

    &--danger {
      border-color: rgba(map-get($colors-dark, danger), 0.5);
      background-color: map-get($colors-dark, danger);
    }

    &--warning {
      border-color: rgba(map-get($colors-dark, warning), 0.5);
      background-color: map-get($colors-dark, warning);
    }

    &--dark {
      color: white;
      background-color: map-get($colors-dark, dark);
    }
  }
}
