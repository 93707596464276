.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #e4e7ea;
  border-radius: 4px;
  padding: 5px;
  background-color: white;
  gap: 5px;

  &__icon {
    color: #e55353;
  }

  &__field {
    flex: 1;
    outline: none;
    border: none;
    width: 0;
    font-size: 14px;
    color: #5c6873;
    background-color: transparent;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
      -webkit-text-fill-color: #5c6873;
    }

    &::placeholder {
      color: #9c9ca0;
    }
  }

  &:focus-within {
    outline: 3px solid rgb(209, 198, 247);
    border: 1px solid rgb(125, 209, 230);
  }

  &--invalid {
    border: 1px solid #e55353;

    &:focus-within {
      outline: 3px solid #f1d2d2;
      border: 1px solid #e55353;
    }
  }
}

:global(.c-dark-theme) {
  .container {
    border: 1px solid #383942;
    background-color: #2f303a;

    &__field {
      color: rgba(white, 0.871);

      &:-webkit-autofill {
        box-shadow: 0 0 0 30px #2f303a inset;
        -webkit-text-fill-color: rgba(white, 0.871);
      }
    }

    &:focus-within {
      outline: 3px solid rgb(39, 39, 95);
      border: 1px solid rgba(255, 255, 255, 0.15);
    }

    &--invalid {
      border: 1px solid #e55353;

      &:focus-within {
        outline: 3px solid #583737;
        border: 1px solid #e55353;
      }
    }
  }
}
