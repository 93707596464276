.top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
}

.clear {
  outline: none !important;
  border: none;
  background-color: transparent;
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

:global(.c-dark-theme) {
  .clear {
    color: white;
  }
}
