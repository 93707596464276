.container {
  width: 100%;

  :global {
    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker__input-container {
      div.react-datepicker__input-container input {
        width: 100%;
      }
    }
  }
}
