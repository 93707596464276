.container {
  position: relative;
  height: 100%;
}

.table {
  position: absolute;
  top: 0;
  bottom: 0;
}
