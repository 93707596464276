.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    width: 100%;
    height: 209px !important;
  }
}

.card {
  align-items: center;
  margin-top: 15px;
  width: 100%;
  height: 209px;

  &__body {
    display: flex;
    align-items: center;
  }
}