.container {
  margin: 0 !important;
  box-shadow: none !important;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;

  .headerSection {
    display: flex;
    align-items: center;
    gap: 10px;

    .action {
      white-space: nowrap;
    }

    .dateSection {
      display: flex;
    }

    .numberOfRowsDisplay {
      width: 50px;
    }

    .clearButton {
      white-space: nowrap;
    }

    .rowsSelectWrapper {
      width: 70px;
    }

    &--left {
      flex-wrap: wrap;
    }

    &--wrap {
      flex-wrap: wrap;
    }
  }
}

.table {
  flex: 1;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 40px;

  .footerTotals {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .innerPagination {
    margin: 0;
  }
}

.btnDuplicates {
  padding: 10px;
  border-radius: 14px;
  margin: 0 10px;
  cursor: pointer;
  background-color: rgba(236, 236, 236, 0.067);

  &:hover {
    background-color: rgba(221, 221, 221, 0.135);
  }
}