.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &--alignStart {
    align-items: flex-start;
  }

  &--alignEnd {
    align-items: flex-end;
  }
}

.dropdown {
  position: absolute;
  top: calc(100% + 5px);
}
