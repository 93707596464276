.defaultOption[disabled] {
  display: none;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  &__field {
    border: 1px solid #e4e7ea;
    border-radius: 4px;
    padding: 1px 3px;
    width: 100% !important;
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1.5;
    color: #5c6873;
    background-clip: padding-box;
    background-color: white;

    &:focus {
      outline: 3px solid rgb(209, 198, 247) !important;
      border: 1px solid rgb(125, 209, 230) !important;
    }

    &--invalid {
      border-color: #e55353 !important;
    }
  }

  &__icon {
    position: absolute;
    margin-right: 10px;
    color: #e55353;
  }
}

:global(.c-dark-theme) {
  .container__field {
    border-color: rgba(255, 255, 255, 0.09);
    color: rgba(255, 255, 255, 0.87);
    background-color: rgba(255, 255, 255, 0.05);

    .container_option {
      color: rgba(0, 0, 0, 0.87);
    }

    &:focus {
      outline: 3px solid rgb(39, 39, 95) !important;
      border: 1px solid rgba(255, 255, 255, 0.15) !important;
    }
  }

  .defaultOption {
    font-weight: bold;
    color: black;
  }
}
