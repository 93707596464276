.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  width: 100%;
  min-width: 0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 14%), 0 1px 2px rgb(0 0 0 / 18%);
  word-wrap: break-word;
  background-clip: border-box;
}

:global(.c-dark-theme) {
  .container {
    background-color: #24252f;
  }
}
