.container {
  display: flex;
  justify-content: center;
  overflow: auto;
  height: 100%;

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1200px;
    gap: 40px 50px;
  }
}

.search {
  display: flex;

  .searchBtn {
    outline: 0 !important;
    border-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &:disabled {
      opacity: 1;
      cursor: default;
    }
  }

  .dateBox {
    width: 160px;
  }
}

.actions {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.statistics {
  display: grid;
  justify-content: center;
  grid-template-columns: min-content min-content;
  padding: 0 20px;
  gap: 10px 80px;
}

.title {
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(white, 0.5);
  padding: 15px;
  font-size: 28px;
  text-align: center;
}

.reservationsButtons {
  display: flex;
  align-items: flex;
  justify-content: space-between;
  border: none;
  padding: 10px;
}

.searchBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
