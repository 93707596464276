@import '../../styles/variables.scss';

.container {
  position: relative;
  display: inline-flex;
  max-width: 100%;

  &__text {
    position: absolute;
    top: 100%;
    z-index: map-get($layers, above_table_hovered_row);
    border-radius: 6px;
    padding: 5px 6px;
    width: max-content;
    max-width: 200px;
    font-size: 12px;
    white-space: normal;
    color: white;
    background-color: black;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    transition-delay: 0.5s;
  }

  &:hover {
    .container {
      &__text {
        opacity: 1;
        visibility: visible;
        text-align: center;
      }
    }
  }
}
