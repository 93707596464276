.container {
  width: 100%;

  &--invalid {
    border: solid 1px #e55353;
    border-radius: 4px;
  }
}

.select {
  opacity: 0.8;
}

.noSelect {
  opacity: 1;
}