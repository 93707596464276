.navigator {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  border: none;
  padding: 3px 5px;
  color: #bbb;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: rgba(black, 0.05);
  }
}

:global(.c-dark-theme) {
  .navigator {
    &:hover {
      color: white;
      background-color: rgba(black, 0.15);
    }
  }
}
