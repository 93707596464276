.container {
  position: relative;
  display: flex;
  align-items: center;
  transition: width 0.3s ease;

  &__input {
    padding-left: 30px;
    width: 100%;
    transition: background-color 0.3s ease;

    &:hover {
      &:not(:focus) {
        background-color: rgba(white, 0.15);
        cursor: pointer;
      }
    }
  }

  &__icon {
    position: absolute;
    left: 10px;
  }

  &:focus-within,
  &--filled {
    width: 200px;
  }

  &--s {
    width: 100px;
  }

  &--m {
    width: 150px;
  }

  &--l {
    width: 200px;
  }
}
