.mMap {
  width: 100%;
  height: 100%;
}

.mapContainer {
  overflow: hidden !important;
  border-radius: 8px !important;
  width: 100%;
  height: 100%;
}

.topBar {
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;

  .searchContainer {
    width: 340px;
  }
}

.cluster {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: radial-gradient(#4357af93, #d8d8d800);

  &__label {
    font-size: 1.5rem;
    color: black;
  }
}
