.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .contentWrapper {
    overflow: auto;

    .content {
      height: 100%;
    }
  }
}

.header {
  padding: 6px;

  &--action {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.actions {
  position: absolute;
  right: 0;
  top: 18px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-left: auto;
  color: #6f6fff;

  &--dot {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50em;
    width: 100px;
    height: 25px;
    color: white;

    span {
      padding: 4px;
      font-weight: bold;
      font-size: 12px;
      text-align: inherit;
    }
  }

  &--text {
    margin-right: 12px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
