.container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.button {
  margin: 0 0 0 10px;
  max-width: 140px;
}
