.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  border-radius: 8px !important;

  :global(.leaflet-div-icon) {
    border: none;
    background: none;
  }
}

.content {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;

  .actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 20px;
  }
}

.markerIcon {
  position: relative;
  color: red;
  border: none;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px;
  }

  .pin {
    height: 30px;
  }

  .address {
    position: absolute;
    top: -25px;
    color: white;
    background-color: rgba(94, 94, 94, 0.653);
    padding: 2px 6px;
    border-radius: 8px;
    white-space: nowrap;
    box-shadow: 2px 2px 8px rgb(195, 195, 195);
  }
}
