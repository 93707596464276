@import '../../styles/variables.scss';

.container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: map-get($layers, above_layout);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .spinner {
    margin: auto;
    margin-top: 30vh;
    width: 160px;
    height: 160px;
    text-align: center;

    .percent {
      display: inline-block;
    }
  }
}
