.container {
  width: 209px;
  height: 209px;

  .body {
    display: flex;
    flex-direction: column;
  }
}

.title {
  font-size: 1.1rem;

  .icon {
    margin-right: 10px;
  }
}

.content {
  flex: 1;
  font-weight: bold;
  font-size: 5.6rem;
  text-align: center;
}
