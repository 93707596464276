.container {
  display: flex;
  gap: 15px;

  .item {
    display: flex;
    align-items: center;
    gap: 20px;

    &__text {
      margin: 0;
      font-size: 14px;
      white-space: nowrap;
    }
  }

  &--col {
    flex-direction: column;
  }
}