@import '../../styles/variables.scss';

.popper {
  z-index: map-get($layers, above_layout) !important;

  select {
    margin: 10px;
    border: none;
    font-weight: 700;
    color: #e1e1e1;
    background-color: transparent;
  }
}
