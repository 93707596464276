@import '../../styles/variables.scss';

.hide {
  display: none;
}

.container {
  z-index: map-get($layers, above_card);
  list-style-type: none;
  overflow: hidden;
  margin-bottom: 0;
  border-radius: 5px;
  padding: 4px 0;
  width: 200px;
  box-shadow: 4px 4px 14px -9px black;
  background-color: white;
  padding-inline-start: 0;

  .item {
    border-radius: 0;
    width: 100%;
    text-align: left;

    &:not(:disabled) {
      &:hover {
        background-color: #ecedef;
      }
    }
  }
}

.checkboxListItem {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.separateGroup {
  margin: 4px 0;
  border-top: 1px solid rgb(182, 182, 182);
  height: 1px;
}

:global(.c-dark-theme) {
  .item {
    &__icon {
      color: white;
    }

    &__text {
      color: white;
    }
  }

  .settingsIcon {
    color: white;
  }

  .container {
    background-color: #34343a;

    .item {
      &:not(:disabled) {
        &:hover {
          background-color: #4c4f54;
        }
      }
    }
  }
}
