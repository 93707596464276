.back {
  margin: 15px;
  width: 1.5rem !important;
  height: 1.5rem !important;
  cursor: pointer;

  &:hover {
    color: black;
  }
}

:global(.c-dark-theme) {
  .back {
    color: rgba(white, 0.87);

    &:hover {
      color: whitesmoke;
    }
  }
}
