.container {
  margin-bottom: 0;
  border-color: #d8dbdf;
  border-bottom: 1px solid #d8dbdf;
  border-radius: 3px 3px 0 0;
  padding: 8px;
}

:global(.c-dark-theme) {
  .container {
    border-color: rgba(255, 255, 255, 0.075);
    background-color: #282933;
  }
}
